var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "neta-header" },
    [
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { xs: 10 } }, [
            _c(
              "div",
              { staticClass: "header-left" },
              [
                _c(
                  "a-row",
                  { attrs: { type: "flex", align: "middle" } },
                  [
                    _c("a-col", { attrs: { xs: 0, sm: 0, md: 4, push: 1 } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/magyarorszag_logo_color.png")
                        }
                      })
                    ]),
                    _c(
                      "a-col",
                      { attrs: { xs: 24, sm: 24, md: 20, pull: 0 } },
                      [_c("HeaderTitleComponent")],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { xs: 14 } }, [
            _c(
              "div",
              { staticClass: "header-right" },
              [
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      {
                        staticClass: "header-right-user-info",
                        attrs: { xs: 0, md: 16 }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left" },
                          [
                            _c("div", { staticClass: "username" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.userName) +
                                  "\n                                "
                              )
                            ]),
                            _vm.roles.length > 1
                              ? _c(
                                  "a-select",
                                  {
                                    staticClass: "role-select",
                                    attrs: { size: "small" },
                                    model: {
                                      value: _vm.selectedRole,
                                      callback: function($$v) {
                                        _vm.selectedRole = $$v
                                      },
                                      expression: "selectedRole"
                                    }
                                  },
                                  _vm._l(_vm.roles, function(role, index) {
                                    return _c(
                                      "a-select-option",
                                      { key: role, attrs: { value: role } },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(_vm.getRoleLabel(role)) +
                                            "\n                                    "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _c("div", { staticClass: "role" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.getRoleLabel(_vm.selectedRole)
                                      ) +
                                      "\n                                "
                                  )
                                ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "right" }, [
                          _vm.office && _vm.office.name
                            ? _c("div", { staticClass: "officename" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.office.name) +
                                    "\n                                "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _c(
                      "a-col",
                      { attrs: { xs: 24, md: 8 } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "bars",
                              type: "primary",
                              size: "large"
                            },
                            on: {
                              click: function($event) {
                                _vm.drawerOpen = !_vm.drawerOpen
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                Menü\n                            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-drawer",
                  {
                    attrs: {
                      title: "Menü",
                      placement: "right",
                      closable: true,
                      visible: _vm.drawerOpen
                    },
                    on: {
                      close: function($event) {
                        _vm.drawerOpen = !_vm.drawerOpen
                      }
                    }
                  },
                  [
                    _c(
                      "a-menu",
                      {
                        attrs: {
                          mode: "vertical",
                          selectedKeys: _vm.selectedMenu
                        },
                        on: { select: _vm.menuSelected }
                      },
                      [
                        _c(
                          "a-menu-item-group",
                          { key: "nav-group", attrs: { title: "Navigáció" } },
                          [
                            _c(
                              "a-menu-item",
                              { key: "neta-list" },
                              [
                                _c("a-icon", { attrs: { type: "table" } }),
                                _vm._v(
                                  "\n                                    Lista\n                                "
                                )
                              ],
                              1
                            ),
                            _vm.canAddAttraction
                              ? _c(
                                  "a-menu-item",
                                  { key: "neta-new-attraction" },
                                  [
                                    _c("a-icon", { attrs: { type: "tag" } }),
                                    _vm._v(
                                      "\n                                    Vonzerő felvétele\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.canAddAttraction
                              ? _c(
                                  "a-menu-item",
                                  {
                                    key: "neta-new-event",
                                    attrs: { disabled: false }
                                  },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "calendar" }
                                    }),
                                    _vm._v(
                                      "\n                                    Esemény felvétele\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.canAddAttraction && !_vm.isMobileApp
                              ? _c(
                                  "a-menu-item",
                                  { key: "neta-new-multi-attraction" },
                                  [
                                    _c("a-icon", { attrs: { type: "tags" } }),
                                    _vm._v(
                                      "\n                                    Multi TOB felvétele\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.canAddAttraction && !_vm.isMobileApp
                              ? _c(
                                  "a-menu-item",
                                  { key: "neta-new-multi-event" },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "calendar" }
                                    }),
                                    _vm._v(
                                      "\n                                    Multi esemény felvétele\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isOfficeEmployee
                              ? _c(
                                  "a-menu-item",
                                  { key: "office" },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "setting" }
                                    }),
                                    _vm._v(
                                      "\n                                    Tourinform Iroda\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isAdmin || _vm.isViewer
                              ? _c(
                                  "a-menu-item",
                                  { key: "tmr-export" },
                                  [
                                    _c("a-icon", { attrs: { type: "export" } }),
                                    _vm._v(
                                      "\n                                    TMR Export\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isAdmin || _vm.isViewer
                              ? _c(
                                  "a-menu-item",
                                  { key: "office-export" },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "database" }
                                    }),
                                    _vm._v(
                                      "\n                                    Iroda Export\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isAdmin || _vm.isViewer
                              ? _c(
                                  "a-menu-item",
                                  { key: "specialist-export" },
                                  [
                                    _c("a-icon", { attrs: { type: "export" } }),
                                    _vm._v(
                                      "\n                                    Specialista Export\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.canAddAttraction && !_vm.isMobileApp
                              ? _c(
                                  "a-menu-item",
                                  { key: "newspaper" },
                                  [
                                    _c("a-icon", {
                                      attrs: { type: "info-circle" }
                                    }),
                                    _vm._v(
                                      "\n                                    Faliújság\n                                "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("a-menu-divider"),
                        _c(
                          "a-menu-item",
                          { key: "login" },
                          [
                            _c("a-icon", { attrs: { type: "logout" } }),
                            _vm._v(
                              "\n\n                                Kilépés\n                            "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("a-divider"),
                    _c("h2", [
                      _vm._v(
                        "\n                            Adatok:\n                        "
                      )
                    ]),
                    _c("div", { staticClass: "profile-data-container" }, [
                      _c("div", { staticClass: "profile-data-title" }, [
                        _vm._v(
                          "\n                                Felhasználónév:\n                            "
                        )
                      ]),
                      _c("div", { staticClass: "profile-data-description" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.userName) +
                            "\n                            "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "profile-data-container" }, [
                      _c("div", { staticClass: "profile-data-title" }, [
                        _vm._v(
                          "\n                                E-mail:\n                            "
                        )
                      ]),
                      _c("div", { staticClass: "profile-data-description" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.email) +
                            "\n                            "
                        )
                      ])
                    ]),
                    _vm.office && _vm.office.name
                      ? _c("div", { staticClass: "profile-data-container" }, [
                          _c("div", { staticClass: "profile-data-title" }, [
                            _vm._v(
                              "\n                                Iroda neve:\n                            "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "profile-data-description" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.office.name) +
                                  "\n                            "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.office && _vm.office.phone
                      ? _c("div", { staticClass: "profile-data-container" }, [
                          _c("div", { staticClass: "profile-data-title" }, [
                            _vm._v(
                              "\n                                Iroda telefonszáma:\n                            "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "profile-data-description" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.office.phone) +
                                  "\n                            "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("strong", [
                        _vm._v(
                          "\n                                NETA 2.0 verzió:\n                            "
                        )
                      ]),
                      _vm._BACKENDVERSION
                        ? _c("div", [
                            _vm._v(
                              "\n                                Backend: "
                            ),
                            _c("span", { staticClass: "version backend" }, [
                              _vm._v("v" + _vm._s(_vm._BACKENDVERSION))
                            ])
                          ])
                        : _vm._e(),
                      _vm._VERSION
                        ? _c("div", [
                            _vm._v(
                              "\n                                Frontend: "
                            ),
                            _c("span", { staticClass: "version" }, [
                              _vm._v("v" + _vm._s(_vm._VERSION))
                            ]),
                            _c("br"),
                            _vm._v("\n                                Build: "),
                            _c("span", { staticClass: "version" }, [
                              _vm._v(_vm._s(_vm._BUILD_DATE))
                            ])
                          ])
                        : _vm._e(),
                      _c("br"),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/api/v1/companies/1/files/64/view",
                            target: "_blank",
                            title: "Adatkezelési tájékoztató (felhasználó)"
                          }
                        },
                        [
                          _vm._v(
                            "\n                                Adatkezelési tájékoztató\n                            "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }