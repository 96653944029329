<template>
  <div id="app-base-container">
    <a-layout :style="{ minHeight: '100vh' }">
      <a-layout-header id="main-layout-header">
        <NetaHeaderComponent />
      </a-layout-header>

      <a-layout-content>
        <NetaProgressbarComponent />

        <keep-alive include="NetaListView">
          <router-view />
        </keep-alive>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import NetaHeaderComponent from '@/components/neta/NetaHeaderComponent.vue';

import NetaProgressbarComponent from '@/components/neta/NetaProgressbarComponent.vue';

export default {
  components: {
    NetaHeaderComponent,
    NetaProgressbarComponent
  },
}
</script>

<style lang="scss" scoped>
.ant-layout {
  background-image: url('~@/assets/images/wow_pattern_web.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.ant-layout-header {
  @include xs {
    padding: 0 10px;
  }

  background-image: url('~@/assets/images/neta_header_mask.png');
  background-position: center center;
  background-color: $primary-blue;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
