<template>
    <div class="neta-header">
        <a-row>
            <a-col :xs="10">
                <div class="header-left">
                    <a-row type="flex" align="middle">
                        <a-col :xs="0" :sm="0" :md="4" :push="1">
                            <img src="@/assets/images/magyarorszag_logo_color.png" />
                        </a-col>

                        <a-col :xs="24" :sm="24" :md="20" :pull="0">
                            <HeaderTitleComponent />
                        </a-col>
                    </a-row>
                </div>
            </a-col>

            <a-col :xs="14">
                <div class="header-right">
                    <a-row>
                        <a-col :xs="0" :md="16" class="header-right-user-info">
                            <div class="left">
                                <div class="username">
                                    {{ userName }}
                                </div>

                                <a-select v-if="roles.length > 1" v-model="selectedRole" size="small"
                                          class="role-select">
                                    <a-select-option v-for="(role, index) in roles" :value="role" :key="role">
                                        {{ getRoleLabel(role) }}
                                    </a-select-option>
                                </a-select>

                                <div class="role" v-else>
                                    {{ getRoleLabel(selectedRole) }}
                                </div>
                            </div>

                            <div class="right">
                                <div class="officename" v-if="office && office.name">
                                    {{ office.name }}
                                </div>
                            </div>
                        </a-col>

                        <a-col :xs="24" :md="8">
                            <a-button
                                    icon="bars"
                                    type="primary"
                                    size="large"
                                    v-on:click="drawerOpen = !drawerOpen"
                            >
                                Menü
                            </a-button>
                        </a-col>
                    </a-row>

                    <a-drawer
                            title="Menü"
                            placement="right"
                            :closable="true"
                            v-on:close="drawerOpen = !drawerOpen"
                            :visible="drawerOpen"
                    >
                        <a-menu
                                mode="vertical"
                                :selectedKeys="selectedMenu"
                                v-on:select="menuSelected"
                        >
                            <a-menu-item-group key="nav-group" title="Navigáció">
                                <a-menu-item key="neta-list">
                                    <a-icon type="table" />
                                    Lista
                                </a-menu-item>

                                <!--                <a-menu-item key="neta-new">-->
                                <!--                  <a-icon type="file-add" />-->
                                <!--                  Objektum felvétele-->
                                <!--                </a-menu-item>-->

                                <a-menu-item key="neta-new-attraction" v-if="canAddAttraction">
                                    <a-icon type="tag" />
                                    Vonzerő felvétele
                                </a-menu-item>

                                <a-menu-item key="neta-new-event" :disabled="false" v-if="canAddAttraction">
                                    <a-icon type="calendar" />
                                    Esemény felvétele
                                </a-menu-item>

                                <a-menu-item key="neta-new-multi-attraction" v-if="canAddAttraction && !isMobileApp">
                                    <a-icon type="tags" />
                                    Multi TOB felvétele
                                </a-menu-item>

                                <a-menu-item key="neta-new-multi-event" v-if="canAddAttraction && !isMobileApp">
                                    <a-icon type="calendar" />
                                    Multi esemény felvétele
                                </a-menu-item>

<!--                                <a-menu-item key="neta-new-hungarikum" v-if="isAdmin || isHungarikum">-->
<!--                                    <a-icon type="tag" />-->
<!--                                    Hungarikum felvétele-->
<!--                                </a-menu-item>-->

                                <a-menu-item key="office" v-if="isOfficeEmployee">
                                    <a-icon type="setting" />
                                    Tourinform Iroda
                                </a-menu-item>

                                <a-menu-item key="tmr-export" v-if="isAdmin || isViewer">
                                    <a-icon type="export" />
                                    TMR Export
                                </a-menu-item>

                                <a-menu-item key="office-export" v-if="isAdmin || isViewer">
                                    <a-icon type="database" />
                                    Iroda Export
                                </a-menu-item>

                                <a-menu-item key="specialist-export" v-if="isAdmin || isViewer">
                                    <a-icon type="export" />
                                    Specialista Export
                                </a-menu-item>

                                <a-menu-item key="newspaper" v-if="canAddAttraction && !isMobileApp">
                                    <a-icon type="info-circle" />
                                    Faliújság
                                </a-menu-item>
                            </a-menu-item-group>

                            <a-menu-divider />

                            <a-menu-item key="login">
                                <a-icon type="logout" />

                                Kilépés
                            </a-menu-item>
                        </a-menu>

                        <a-divider />

                        <h2>
                            Adatok:
                        </h2>

                        <div class="profile-data-container">
                            <div class="profile-data-title">
                                Felhasználónév:
                            </div>

                            <div class="profile-data-description">
                                {{ userName }}
                            </div>
                        </div>

                        <div class="profile-data-container">
                            <div class="profile-data-title">
                                E-mail:
                            </div>

                            <div class="profile-data-description">
                                {{ email }}
                            </div>
                        </div>

                        <div class="profile-data-container" v-if="office && office.name">
                            <div class="profile-data-title">
                                Iroda neve:
                            </div>

                            <div class="profile-data-description">
                                {{ office.name }}
                            </div>
                        </div>

                        <!--            <div class="profile-data-container" v-if="office && office.address">-->
                        <!--              <div class="profile-data-title">-->
                        <!--                Iroda címe:-->
                        <!--              </div>-->

                        <!--              <div class="profile-data-description">-->
                        <!--                {{ office.address }}-->
                        <!--              </div>-->
                        <!--            </div>-->

                        <div class="profile-data-container" v-if="office && office.phone">
                            <div class="profile-data-title">
                                Iroda telefonszáma:
                            </div>

                            <div class="profile-data-description">
                                {{ office.phone }}
                            </div>
                        </div>

                        <div>
                            <strong>
                                NETA 2.0 verzió:
                            </strong>

                            <div v-if="_BACKENDVERSION">
                                Backend: <span class="version backend">v{{ _BACKENDVERSION }}</span>
                            </div>

                            <div v-if="_VERSION">
                                Frontend: <span class="version">v{{ _VERSION }}</span>
                                <br />
                                Build: <span class="version">{{ _BUILD_DATE }}</span>
                            </div>

                            <br />

                            <a href="/api/v1/companies/1/files/64/view" target="_blank"
                               title="Adatkezelési tájékoztató (felhasználó)">
                                Adatkezelési tájékoztató
                            </a>
                        </div>
                    </a-drawer>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import HeaderTitleComponent from '@/components/shared/header/HeaderTitleComponent.vue';
import { USER_LOGOUT } from '@/action_types.js';
import { mapState } from 'vuex';
import { SET_SELECTED_ROLE } from "@/mutation_types";
import Localstorage from "@/helpers/localstorage";

export default {
    components: {
        HeaderTitleComponent
    },

    data() {
        return {
            drawerOpen: false,

            roleLabels: {
                'ROLE_OFFICE_LEADER': 'Irodavezető',
                'ROLE_OFFICE_EMPLOYEE': 'Munkatárs',
                'ROLE_OFFICE_SPECIALIST': 'Specialista',
                'ROLE_MTU_ADMIN': 'MTÜ Admin',
                'ROLE_MTU_VIEWER': 'MTÜ Betekintő',
                'ROLE_HUNGARIKUM': 'Hungarikum szerkesztő',
                'ROLE_MOBILE_APP': 'Mobil app. szerkesztő',
            }
        }
    },

    computed: {
        _VERSION() {
            return process.env.VUE_APP_VERSION
        },

        _BUILD_DATE() {
            return process.env.VUE_APP_BUILD_DATE
        },

        _BACKENDVERSION() {
            return this.$store.state.backendVersion
        },

        selectedMenu() {
            return [`${this.$route.name}`]
        },

        ...mapState({
            userName: state => state.user.userName,
            office: state => state.user.office,
            email: state => state.user.email,
            roles: state => state.user.roles
        }),

        selectedRole: {
            get() {
                return this.$store.state.user.selectedRole;
            },
            set(value) {
                this.$store.commit(SET_SELECTED_ROLE, {
                    selectedRole: value
                });
            }
        },

        canAddAttraction() {
            return this.isAdmin || this.isOfficeEmployee || this.officeSpecialist || this.isMobileApp;
        },

        isAdmin() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MTU_ADMIN']);
        },

        isMobileApp() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MOBILE_APP']);
        },

        isViewer() {
            return this.$store.getters.hasRoleAvailable(['ROLE_MTU_VIEWER']);
        },

        isOfficeEmployee() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_EMPLOYEE', 'ROLE_OFFICE_LEADER'])
        },

        isOfficeLeader() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_LEADER'])
        },

        isHungarikum() {
            return this.$store.getters.hasRoleAvailable(['ROLE_HUNGARIKUM'])
        },

        officeSpecialist() {
            return this.$store.getters.hasRoleAvailable(['ROLE_OFFICE_SPECIALIST'])
        },
    },

    methods: {
        async logout() {
            await this.$store.dispatch({
                type: USER_LOGOUT
            })
            this.$router.push({ name: 'login' })
        },

        openDrawer() {
            this.drawerOpen = true;
        },

        closeDrawer() {
            this.drawerOpen = false;
        },

        menuSelected({ item, key, selectedKeys }) {
            switch (key) {
                case 'login':
                    this.logout();
                    break;

                case 'newspaper':
                    const jwt = Localstorage.getJwt();

                    window.open('/api/v1/companies/1/newspaper/redirect?token=' + jwt, '_blank');

                    break;

                default:
                    this.closeDrawer();
                    this.$router.push({ name: key });
                    break;
            }
        },

        /**
         * @param role
         * @return {string}
         */
        getRoleLabel(role) {
            return this.roleLabels[role];
        }
    }
}
</script>

<style lang="scss">
.role-select {
  .ant-select-selection {
    background: transparent;
    color: white;
    box-shadow: none;
    min-width: 150px;
    outline: none;
    border-color: transparent;

    i {
      color: white;
    }

    > div {
      margin-left: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.neta-header {
  .header-left {
    img {
      margin-bottom: 10px;
      width: 45px;
      height: auto;
    }
  }

  .header-right {
    text-align: right;

    .header-right-user-info {
      line-height: 15px;
      margin-top: 15px;
      justify-content: flex-end;
      display: flex;

      .role-select {

        margin-top: 3px;
      }

      .left {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-right: 10px;
      }

      .right {
        border-left: 1px solid white;
        align-items: center;
        display: flex;
        padding-left: 10px;
        text-align: right;
        text-transform: uppercase;
      }
    }

    color: white;

    .username {
      margin-right: 10px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .header-router-link {
    display: block;

    @include sm {
      display: none;
    }

    transition: background-color 500ms ease-in-out;
    text-decoration: none;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;

    &.router-link-exact-active {
      background-color: $secondary-magenta;
    }

    &:hover {
      background-color: $secondary-magenta;
    }
  }
}

.profile-data-container {
  margin-bottom: 15px;
  border-bottom: 0.5px solid #E8E8E8;


  .profile-data-title {
    font-weight: bold;
  }
}

.version-holder {
  position: absolute;
  bottom: 8px;

  span {
    color: grey;
    font-size: 12px;

    &.version {
      font-weight: bold;
    }
  }
}

</style>
