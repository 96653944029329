var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress-holder" },
    [
      _vm.showBar
        ? _c("a-progress", {
            staticClass: "progress-bar",
            attrs: {
              percent: _vm.progress,
              status: "active",
              showInfo: false,
              strokeColor: "#38BA9B"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }