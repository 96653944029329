<template>
  <div
      class="progress-holder"
  >
    <a-progress
        class="progress-bar"
        v-if="showBar"
        :percent="progress"
        status="active"
        :showInfo="false"
        strokeColor="#38BA9B"
    >
    </a-progress>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  data() {
    return {
      interval: null,
      progress: 0,
      current_progress: 0,
      progress_step: 0.3,

      showBar: false,
    }
  },

  computed: {
    ...mapGetters({
      showProgressbar: 'getNavigationInProgress'
    })
  },

  watch: {
    showProgressbar: function (newState) {
      if (newState === true) {
        this.startProgress();
      } else if (newState === false) {
        this.current_progress = 99;
      }
    }
  },

  methods: {
    clearTicker() {
      clearInterval(this.interval);
      this.interval = null;
    },

    finishProgress() {
      this.clearTicker();
      this.showBar = false;
      this.current_progress = 0;
      this.progress = 0;
    },

    startProgress() {
      this.progress_step = 0.3
      this.current_progress = 0;
      this.progress = 0;
      this.showBar = true;
      this.interval = setInterval(this.progressTicker, 100)
    },

    progressTicker() {
      this.current_progress = this.current_progress + this.progress_step;
      this.progress = Math.round(
          Math.atan(this.current_progress) / (Math.PI / 2) * 100 * 1000
      ) / 1000;

      if (this.current_progress >= 50) {
        this.progress_step = 0.1
      }

      if (this.current_progress >= 100) {
        this.finishProgress();
      }
    }
  },

  beforeDestroy() {
    this.clearTicker();
  },
}
</script>

<style lang="scss" scoped>
.progress-holder {
  position: relative;
}

.progress-bar {
  position: absolute;
  z-index: 5;
  opacity: 0.8;
  margin-top: -7px;
}
</style>
